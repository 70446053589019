import React from 'react'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'
import BgImage from '../images/bg/solutions-page-banner.jpg'
import SocialIconsFooter from '../components/social-icons-footer'
import { StaticImage } from 'gatsby-plugin-image'

const SupportPage = () => {
    return (
        <Layout pageTitle="PragICTS | Support Desk" og_desc="Support services by PragICTS">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                    </div>

                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem"  data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                                <h2>SUPPORT BY<span> PRAGICTS</span></h2>
                                <p>How we support you.</p>
                                <div className="horizonral-subtitle"><span>SUPPORT</span></div>
                            </div>
                            <a href="#sec1" className="custom-scroll-link hero-start-link"><span>Let's Start</span> <i className="fal fa-long-arrow-down"></i></a>
                        </div>
                    </section>
                    <section data-scrollax-parent="true" id="sec1">
                        <div className="section-subtitle left-pos"  data-scrollax="properties: { translateY: '-250px' }" ><span>//</span>careers</div>
                        <div className="container main-about">
                            <p>We are here and our successes are all due to our valued customers. We are here to serve them to the fullest of our capacity. In this regard how we support them is #1 to us.

PragICTS has an established support apparatus to provide convenient, focused, responsive, and dedicated support to its client. The support services are established as follows

Multi-channel dedicated contact touchpoints for clients to raise their support requests</p>
<p>
<ul className='leftalign'><li><i class="fal fa-arrow-right"></i> Email: <a href="mailto:support@pragicts.com">support@pragicts.com</a></li><li><i class="fal fa-arrow-right"></i> Phone: <a href="tel:+94742703262">+94 74 270 3262</a></li></ul>
</p>

<p>It is recommended that all requests be raised through support@pragicts.com.All emails will be responded to within 10 minutes of receipt of email.

All emails received are auto-forwarded to the key personnel as follows for redundancy and immediate actioning

<div class="row"><div class="col-md-4"><h4>Support Centre</h4><ul><li><i class="fal fa-arrow-right"></i> HOD Support</li><li><i class="fal fa-arrow-right"></i> Support Personnel</li></ul></div><div class="col-md-4"><h4>HODs Service Verticals</h4><ul><li><i class="fal fa-arrow-right"></i> HOD Software Engineering Services</li><li><i class="fal fa-arrow-right"></i> HOD Systems Integration Services</li><li><i class="fal fa-arrow-right"></i> HOD Cloud Services</li></ul></div><div class="col-md-4"><h4>Also copied to the</h4><ul><li><i class="fal fa-arrow-right"></i> CEO</li></ul></div></div>
</p>
<p>The first staff member to receive the support request will respond with immediate effect with CC to <a href="mailto:support@pragicts.com">support@pragicts.com</a>.

On completion of the support request, the customer will be informed with a formal email, SMS notification of the completion of the request.</p>

<p><h3>Escalations</h3><p>PragICTS also provides clients with dedicated contact details for escalations pertaining to their service requests </p><ul><li><i class="fal fa-arrow-right"></i> Email: <a href="mailto:escalations@pragicts.com">escalations@pragicts.com</a></li><li><i class="fal fa-arrow-right"></i> Phone: <a href="tel:+94704598983">+94 70 459 8983</a></li></ul></p>
                       
<p><h3 >General Service Level Parameters of PragICTS Support</h3>
<table class="table" style={{width:'100%',border:'1.5px solid gray'}}><tbody><tr><th style={{borderRight:'1.5px solid grey',borderBottom:'1.5px solid grey',textAlign:'left',padding:'20px',paddingLeft:'10px',fontWeight:'bold'}}>Phone</th><td style={{display:'flex',borderBottom:'1.5px solid grey',flexDirection:'column',alignItems:'flex-start',padding:'20px',paddingLeft:'10px'}}><span><i class="fal fa-arrow-right"></i> Call Answer: <b>30s</b></span><span><i class="fal fa-arrow-right"></i> Return calls on missed calls:<b>15min</b></span></td></tr><tr><th style={{borderRight:'1.5px solid grey',borderBottom:'1.5px solid grey',textAlign:'left',padding:'20px',paddingLeft:'10px',fontWeight:'bold'}}>Email</th><td style={{display:'flex',borderBottom:'1.5px solid grey',flexDirection:'column',alignItems:'flex-start',padding:'20px',paddingLeft:'10px'}}><span><i class="fal fa-arrow-right"></i> Emails confirmation of receipt: <b>10min</b></span></td></tr><tr><th style={{borderRight:'1.5px solid grey',textAlign:'left',padding:'20px',paddingLeft:'10px',fontWeight:'bold'}}>General</th><td style={{display:'flex',flexDirection:'column',alignItems:'flex-start',padding:'20px',paddingLeft:'10px'}}><span><i class="fal fa-arrow-right"></i> Staff allocation on support requests: <b>15min</b></span><span><i class="fal fa-arrow-right"></i> For critical support requests: <b>Immediate</b></span></td></tr></tbody></table>
</p>  

<p><ul><li><i class="fal fa-arrow-right"></i> The customer will be informed of the allocation by the allocated staff.</li><li><i class="fal fa-arrow-right"></i> Once the investigation pertaining to the issue is completed, the issue resolution timeline will be informed to the customer through email</li><li><i class="fal fa-arrow-right"></i> A follow-up will be done with the customer to ensure that is addressed to his/her satisfaction.</li></ul><br/><h3>Monthly Report</h3><ul><li><i class="fal fa-arrow-right"></i> A month-end report will be sent to all customers on support activities on the last working days of the month.</li><li><i class="fal fa-arrow-right"></i> This will also include recommendations and observations.</li></ul><br/><h3>Quarterly Customer Meetings</h3><ul><li><i class="fal fa-arrow-right"></i> A quarterly meeting will be held with the customer by the relevant HOD of PragICTS for feedback and review for the quarter.</li><li><i class="fal fa-arrow-right"></i> All issues raised and discussed will be addressed and an update will be provided to the customer this will also be incorporated into the month-end support report.</li></ul></p>                 
                       
                  
                        </div>
                     
                        <div className="bg-parallax-module" data-position-top="50"  data-position-left="20" data-scrollax="properties: { translateY: '-250px' }"></div>
                        <div className="bg-parallax-module" data-position-top="40"  data-position-left="70" data-scrollax="properties: { translateY: '150px' }"></div>
                        <div className="bg-parallax-module" data-position-top="80"  data-position-left="80" data-scrollax="properties: { translateY: '350px' }"></div>
                        <div className="bg-parallax-module" data-position-top="95"  data-position-left="40" data-scrollax="properties: { translateY: '-550px' }"></div>
                        <div className="sec-lines"></div>
                    </section>
                    <section className="dark-bg sinsec-dec sinsec-dec2 parallax-section">
                        <div className="container">
                            <div className="section-title">
                                <h2>SUPPORT <span>HIGHLIGHTS </span></h2>
                 
                                <div className="horizonral-subtitle"><span>Why Us?</span></div>
                            </div>
                            <div className="fl-wrap">
                                <div className="row">
                                    <div className="features-box col-md-4">
                                        <div className="time-line-icon">
                                            <StaticImage src="../images/icons/support-effective.svg" />
                                        </div>
                                        <h3>Dedicated</h3>
                                        <p>Dedicated personnel and systems to provide quality support with dedicated apparatus.</p>
                                    </div>
                                    <div className="features-box col-md-4">
                                        <div className="time-line-icon">
                                        <StaticImage src="../images/icons/support-redundancy.svg" />
                                        </div>
                                        <h3>Redundancy</h3>
                                        <p>Handled at a company level than at an individual level.</p>
                                    </div>
                                    <div className="features-box col-md-4">
                                        <div className="time-line-icon">
                                        <StaticImage src="../images/icons/support-ease-use.svg" />
                                        </div>
                                        <h3>Ease of Reach</h3>
                                        <p>Reach through email and phone.</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="features-box col-md-4">
                                        <div className="time-line-icon">
                                        <StaticImage src="../images/icons/support-vision.svg" />
                                        </div>
                                        <h3>Continuous Supervision</h3>
                                        <p>Under constant and regular review to track and improve performance and service level</p>
                                    </div>
                                    <div className="features-box col-md-4">
                                        <div className="time-line-icon">
                                        <StaticImage src="../images/icons/support-professional-team.svg" />
                                        </div>
                                        <h3>Professional Staff</h3>
                                        <p>Capable and well-trained staff to handle support requests.</p>
                                    </div>
                                    <div className="features-box col-md-4">
                                        <div className="time-line-icon">
                                        <StaticImage src="../images/icons/support-sla.svg" />
                                        </div>
                                        <h3>Standard Driven</h3>
                                        <p>SOP and SLA based operation for constant delivery of services</p>
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                        <div className="half-bg-dec single-half-bg-dec" data-ran="12"></div>
                        <div className="sec-lines"></div>
                    </section>            
                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default SupportPage
